import styled from "styled-components";

export const ComingSoonBadge = styled.span.attrs({ children: "Coming soon" })`
  display: inline-block;
  color: rgba(0, 0, 0, 0.95);
  border: 1px solid;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 116.4%;
  text-align: center;
  padding: 2px 4px;
  width: max-content;
  margin-left: auto;
`;

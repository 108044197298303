import { useMemo } from "react";
import useSWR from "swr";
import { getAccessToken } from "../APIKeys/utils/getAccessToken";
import { APIResponse } from "../APIKeys/utils/types";

interface Customer {
  free_requests: number;
  total_requests: number;
  created_at: number;
  billing_customer_id: string;
  unique_name: string;
  email: string;
  free_requests_used: number;
  last_name: string;
  company: string;
  first_name: string;
  identity_user_id: string;
  id: string;
  last_count_update: number;
  phone: string;
}

async function getCustomerInfo() {
  const token = await getAccessToken();
  const url = new URL(
    "/v1/customer-system/customer",
    process.env.REACT_APP_API_URL
  );
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = (await res.json()) as APIResponse<Customer>;
  if (json.status === "error") {
    console.error(json.error);
    throw new Error("Failed to fetch customer info");
  }
  return json.data;
}

export function useCustomerInfo() {
  const { data: customer, ...rest } = useSWR(
    ["CUSTOMER_INFO"],
    getCustomerInfo,
    {
      revalidateOnMount: true,
    }
  );

  const computed = useMemo(() => {
    if (customer) {
      const hasFreeTrial = customer.free_requests_used < customer.free_requests;
      const isReachingEndOfTrial =
        customer.free_requests_used > customer.free_requests * 0.75;
      return {
        hasFreeTrial,
        isReachingEndOfTrial,
      };
    }
  }, [customer]);

  return { customer, ...rest, ...(computed || {}) };
}

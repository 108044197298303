import styled from "styled-components";
import { ErrorMessage, Field, Form } from "formik";
import signup from "../../../Assets/Images/signup.png";
import OtpInput from "react-otp-input";
import { Checkbox } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ButtonPrimary } from "../../Common/Button";

export const RegistrationFormWrapper = styled.div`
  margin-top: 32px;
`;

export const CustomForm = styled(Form)`
  display: flex;
  justify-content: center;
`;
export const StyledWarningIcon = styled(WarningOutlined)`
  color: ${({ theme }) => theme.colors.orange};
  font-size: 20px;
`;
export const StyledSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;
export const StyledCheckbox = styled(Checkbox)`
  margin-top: 30px;
  font-weight: 400;
  font-size: 18px;
  // customize the checkbox container
  .ant-checkbox-wrapper {
    margin-right: 8px;
  }

  // customize the checkbox icon
  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid ${({ error }) => (error ? "red" : "rgba(0, 0, 0, 0.5)")};
  }

  // customize the text label next to the checkbox
  .ant-checkbox-wrapper span {
    font-size: 14px;
    color: #333;
  }

  // customize the checked state of the checkbox icon
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: black;
  }

  // customize the checked state of the text label next to the checkbox
  .ant-checkbox-checked .ant-checkbox-wrapper span {
    color: black;
  }

  span.ant-checkbox .ant-checkbox-inner:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    inset-inline-start: 21.5%;
    display: table;
    width: 5.7142857142857135px;
    height: 9.142857142857142px;
    border: 2px solid black;
    border-top: 0;
    border-inline-start: 0;
  }
`;
export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 110px 50px;
  justify-content: space-around;
  width: 406px;
`;
export const FlexBox1 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: space-around;
`;

// export const LoginGoogleButton = styled(GoogleLogin)`
//   box-sizing: border-box;
//   justify-content: center;
//   width: 438px;
//   height: 52px;
//   margin-bottom: 20px;
//   text-align: center;
//   border: 1px solid #000000 !important;
//   border-radius: 6px !important;
// `;

export const Details = styled.p`
  height: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;
export const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 116.4%;
  color: #000000;
`;

export const RadioText = styled.p`
  margin: 0;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const RadioButton = styled.input`
  accent-color: black;
  height: 22px;
  width: 22px;
`;
export const MarginText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 20px;
`;

export const LoginGitButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  justify-content: center;
  width: 438px;
  height: 52px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #000000;
  border-radius: 6px;
`;
export const Divider = styled.h2`
  width: 100%;
  text-align: center;
  background: ${({ theme }) => theme.colors.orange};
  line-height: 0.1em;
  margin: 10px 0 20px;
  border-radius: 10px;
`;
export const SpanText = styled.span`
  background: ${({ theme }) => theme.colors.white};
  padding: 0 10px;

  color: #000000;
`;
export const GoogleText = styled.div`
  width: 200px;
  height: 25px;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  float: left;
  display: flex;
`;
export const Input = styled(Field)`
  margin-bottom: 5px;
  box-sizing: border-box;
  width: 435px;
  height: 51px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 116.4%;

  /* Gray */

  background: ${({ theme }) => theme.colors.gray};
  border: 1px solid ${({ error }) => (error ? "red" : "rgba(0, 0, 0, 0.5)")};
  border-radius: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const CustomLabel = styled.label`
  text-align: initial;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin-bottom: 10px;
  margin-top: 18px;
  color: black;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
`;

export const FieldError = styled(ErrorMessage)`
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 116.4% !important;
  color: #e40521 !important;
`;

export const Button = styled(ButtonPrimary)`
  margin-top: 50px;
  text-align: center;
  text-transform: uppercase;
  width: 435px;
`;

export const InvisibleButton = styled.button`
  background: transparent;
  color: black;
  width: 435px;
  height: 52px;
  border: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  /* identical to box height */

  text-align: center;
`;
export const SubmitButton = styled(Button)`
  margin-bottom: 20px;
  margin-top: 50px;
  max-width: 100%;
`;

export const RegisterContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  font-size: calc(10px + 2vmin);
  color: ${({ theme }) => theme.colors.white};
  width: ${(props) => (props.step === 1 || props.step === 2 ? "50%" : "100%")};
  @media (max-width: 970px) {
    width: 100% !important;
  }
`;

export const DetailsContainer = styled.div`
  background-image: url(${signup});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: ${(props) =>
    props.step === 1 || props.step === 2 ? "flex" : "none"};
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  width: ${(props) => (props.step === 1 || props.step === 2 ? "50%" : "0")};
  color: ${({ theme }) => theme.colors.white};
  background-color: black;

  @media (max-width: 970px) {
    display: none;
  }
`;
export const DetailsTitle = styled.h1`
  width: 407px;
  height: 198px;
  left: 109px;
  top: 80px;
  font-style: normal;
  font-weight: 700;
  font-size: 57px;
  line-height: 116.4%;
  color: #ffffff;
  margin-top: 176px;
`;

export const PageDetails = styled.p`
  width: 407px;
  height: 124px;
  left: 109px;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 116.4%;
  margin-top: 0;
  color: #ffffff;
`;

export const List = styled.ul`
  list-style: none;
  color: black;
  margin: 0;

  & li::before {
    content: "\\2022";
    color: ${({ theme }) => theme.colors.orange};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 20px 0;
  width: 435px;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 4px solid ${({ theme }) => theme.colors.orange};
    border-radius: 10px;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
`;

export const Validations = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: ${(props) =>
    props.condition === true ? "line-through " : "none"};
  color: #000000;
`;
export const ValidationsTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0;
`;

export const Steps = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: black;
`;

export const Title = styled.h2`
  width: 407px;
  height: 38px;
  left: 189px;
  top: 176px;

  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  color: black;
  margin-bottom: 5px;
`;
export const StyledOTPInput = styled(OtpInput)`
  align-items: center;
  padding: 5px;
  height: 51px;
  justify-content: center;
  margin: 20px 0;

  & input {
    width: 40px !important;
    height: 51px;
    background: ${({ theme }) => theme.colors.gray};
    border: 1px solid ${({ error }) => (error ? "red" : "rgba(0, 0, 0, 0.5)")};
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
    border-radius: 6px;

    &:focus {
      outline: none;
      border-color: black;
    }
  }
`;

export const StyledLink = styled(Link)`
  height: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

export const StyledLinkSmallText = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #000000;
  text-decoration: underline;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 7px;
  height: 20px;
  &:hover .TooltipContent {
    visibility: visible;
    opacity: 1;
  }
`;

export const TooltipContent = styled.div`
  visibility: hidden;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  width: 282px;
  height: 133px;
  padding: 20px;

  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: black;
  border-radius: 10px;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opacity: */
  opacity: 0;
  transition: opacity 1s;
`;

import { useCallback } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";
import { getAccessToken } from "./getAccessToken";
import { GET_ALL_KEYS, UseGetAllKeysData } from "./getAllKeys";
import { notification } from "antd";
import { generateIssuedAt } from "./common";
import { APIResponse, OptimisticAPIKey } from "./types";

/**
 * Creates a new API key with the given name
 */
export async function createKey(apiKeyName: string) {
  const endpoint = process.env.REACT_APP_API_URL + "/v1/api-key-system/api-key";
  const res = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify({ name: apiKeyName }),
    // For security reasons, we don't want to cache this response
    cache: "no-store",
  });
  const json = (await res.json()) as APIResponse<{
    api_key: string;
  }>;
  if (json.status === "success") {
    return {
      api_key: json.data.api_key,
    };
  }
}

export const CREATE_API_KEY = "CREATE_API_KEY";

/**
 * The cache key for the create key mutation
 */
export type UseCreateKeyMutationKey = typeof CREATE_API_KEY;

/**
 * The data returned by the create key mutation
 */
export type UseCreateKeyMutationData = Awaited<ReturnType<typeof createKey>>;

/**
 * The error thrown by the create key mutation
 */
export type UseCreateKeyMutationError = Error;

/**
 * The argument for the create key mutation is the name of the key
 */
export type UseCreateKeyMutationArg = string;

/**
 * SWR configuration for the create key mutation
 */
export type UseCreateKeyMutationConfiguration = SWRMutationConfiguration<
  UseCreateKeyMutationData,
  UseCreateKeyMutationError,
  UseCreateKeyMutationArg,
  UseCreateKeyMutationKey,
  UseCreateKeyMutationData
>;

/**
 * Hook to invoke the create key mutation
 */
export function useCreateKeyMutation(
  options: UseCreateKeyMutationConfiguration = {}
) {
  return useSWRMutation<
    UseCreateKeyMutationData,
    UseCreateKeyMutationError,
    UseCreateKeyMutationKey,
    UseCreateKeyMutationArg
  >(
    CREATE_API_KEY,
    (_, { arg: apiKeyName }) => {
      return createKey(apiKeyName);
    },
    options
  );
}

/**
 * Hook to invoke the create kay mutation with optimistic updates
 */
export function useOptimisticCreateKey() {
  const { mutate } = useSWRConfig();
  const { trigger, ...mutation } = useCreateKeyMutation({
    onError: (error, _key, _config) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const createKey = useCallback(
    async (apiKeyName: string) => {
      mutate<UseGetAllKeysData>(
        GET_ALL_KEYS,
        (data = []) => {
          // Update the cache immediately with new key
          const optimisitKey: OptimisticAPIKey = {
            name: apiKeyName,
            status: "ACTIVE",
            issued_at: generateIssuedAt(),
            api_key_masked: "********************",
            api_key_hash: "********************",
            _optimistic: true,
          };
          return [optimisitKey, ...data];
        },
        {
          revalidate: false,
        }
      );
      // Trigger the API call to create the key
      await trigger(apiKeyName, {
        throwOnError: false,
      });
      // Revalidate the cache to ensure it's up to date
      mutate(GET_ALL_KEYS);
    },
    [mutate, trigger]
  );

  return [createKey, mutation] as const;
}

import styled from "styled-components";
import UnderConstruction from "./UnderConstruction";

export default function Usage() {
  return (
    <Container>
      <Heading>Usage</Heading>
      <Paragraph>
        Below you'll find a summary of how any given API key in your account has
        been used so far.
      </Paragraph>
      <UnderConstruction />
    </Container>
  );
}

const Container = styled.div`
  padding: 1em 24px;
  @media (min-width: 768px) {
    padding: 1em 40px;
  }
  max-width: 1000px;
  min-height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 0 0 8px;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 24px;
`;

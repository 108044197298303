import styled from "styled-components";
import IMG_SRC from "./UnderConstruction.png";

export default function UnderConstruction() {
  return (
    <Wrapper>
      <Container>
        <Image />
        <Text>
          We're working hard to give you better tools and let you enjoy
          botshelf.ai to the fullest. Stay tuned, we will keep you posted.
        </Text>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  // flex: 1;
  background: #ffffff;
  border-radius: 20px;

  display: grid;
  place-content: center;
`;

const Container = styled.div`
  padding: 100px 24px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img.attrs({
  src: IMG_SRC,
  alt: "",
  role: "presentation",
  width: 1440,
  height: 800,
})`
  width: 100%;
  max-width: 380px;
  height: auto;
  object-fit: contain;
  margin-bottom: 48px;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin: 0;
`;

import { Auth } from "aws-amplify";
import { Formik } from "formik";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../../../AppContext";
import googleIcon from "../../../Assets/Images/googleIcon.svg";
import { CustomLabel, Input, Wrapper } from "../Register/Styled";

export default function LogInSection() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setResetPageStep } = useContext(AuthContext);

  const userLoginMethod = () => {
    if (user?.username?.includes("Google_")) {
      return "GOOGLE";
    } else {
      return "COGNITO";
    }
  };

  const handleLogout = useCallback(async () => {
    navigate("/reset-password");
    await Auth.forgotPassword(user.attributes.email);
    setResetPageStep(2);
  }, [navigate, setResetPageStep, user.attributes.email]);

  if (!user) return null;

  return (
    <>
      <Heading>Log in info</Heading>

      {userLoginMethod() === "GOOGLE" ? (
        <Container>
          <Wrapper>
            <img src={googleIcon} alt="googleICON" />
            <Title>Google account</Title>
          </Wrapper>
          <p style={{ marginLeft: "32px" }}>{user.attributes.email}</p>
        </Container>
      ) : (
        <Formik
          initialValues={{
            email: user.attributes.email,
            password: "hardCodedFakePassword",
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "20px",
              paddingLeft: "24px",
              paddingBottom: "24px",
            }}
          >
            <CustomLabel htmlFor="email">Email</CustomLabel>
            <Input type="text" name="email" disabled />
            <CustomLabel htmlFor="password">Password</CustomLabel>
            <InlineWrapper>
              <Input type="password" name="password" initialV disabled />
              <StyledButton onClick={() => handleLogout()}>
                Reset Password
              </StyledButton>
            </InlineWrapper>
          </form>
        </Formik>
      )}
    </>
  );
}

const Heading = styled.h1`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 116.4%;
  margin: 85px 0 15px;
`;

const InlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
`;

export const StyledButton = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 116.4%;
  text-decoration-line: underline;
  color: #000000;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 116.4%;
  margin-left: 10.4px;
`;

const Container = styled.div`
  background-color: white;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-radius: 20px;
`;

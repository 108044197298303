import { notification } from "antd";
import { useCallback } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";
import { getAccessToken } from "./getAccessToken";
import { GET_ALL_KEYS, UseGetAllKeysData } from "./getAllKeys";
import { APIKey, OptimisticAPIKey, APIResponse } from "./types";

/**
 * Deletes the given API key
 */
export async function deleteKey(apiKeyHash: string) {
  const endpoint = process.env.REACT_APP_API_URL + "/v1/api-key-system/api-key";
  const res = await fetch(endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    body: JSON.stringify({ api_key_hash: apiKeyHash }),
    // For security reasons, we don't want to cache this response
    cache: "no-store",
  });
  const json = (await res.json()) as APIResponse;
  if (json.status === "error") {
    throw new Error("Failed to delete API key");
  }
  return {};
}

export const DELETE_API_KEY = "DELETE_API_KEY";

/**
 * The cache key for the delete key mutation
 */
export type UseDeleteKeyMutationKey = typeof DELETE_API_KEY;

/**
 * The data returned by the delete key mutation
 */
export type UseDeleteKeyMutationData = Awaited<ReturnType<typeof deleteKey>>;

/**
 * The error returned by the delete key mutation
 */
export type UseDeleteKeyMutationError = Error;

/**
 * The argument for the delete key mutation is the hash of the key
 */
export type UseDeleteKeyMutationArg = string;

/**
 * SWR configuration for the delete key mutation
 */
export type UseDeleteKeyMutationConfiguration = SWRMutationConfiguration<
  UseDeleteKeyMutationData,
  UseDeleteKeyMutationError,
  UseDeleteKeyMutationArg,
  UseDeleteKeyMutationKey,
  UseDeleteKeyMutationData
>;

/** Hook to invoke the delete key mutation */
export function useDeleteKeyMutation(
  options: UseDeleteKeyMutationConfiguration = {}
) {
  return useSWRMutation<
    UseDeleteKeyMutationData,
    UseDeleteKeyMutationError,
    UseDeleteKeyMutationKey,
    UseDeleteKeyMutationArg
  >(DELETE_API_KEY, (_, { arg: apiKeyHash }) => deleteKey(apiKeyHash), options);
}

/**
 * Hook to invoke the delete key mutation with optimistic updates
 */
export function useOptimisticDeleteKey() {
  const { mutate } = useSWRConfig();
  const { trigger, ...mutation } = useDeleteKeyMutation({
    onError: (error, _key, _config) => {
      notification.error({
        message: error.message,
      });
    },
    onSuccess: () => {
      notification.success({
        message: "Key deleted",
      });
    },
  });

  const deleteKey = useCallback(
    async (apiKeyHash: string) => {
      mutate<UseGetAllKeysData>(
        GET_ALL_KEYS,
        function optimisitUpdate(data) {
          // Update the cache immediately with deleted key
          return data?.map((key): APIKey => {
            if (key.api_key_hash === apiKeyHash) {
              const optimisticKey: OptimisticAPIKey = {
                ...key,
                status: "DELETED",
                _optimistic: true,
              };
              return optimisticKey;
            }
            return key;
          });
        },
        {
          revalidate: false,
        }
      );
      // Trigger the API call to delete the key
      await trigger(apiKeyHash);
      // Revalidate the cache to ensure it's up to date
      mutate(GET_ALL_KEYS);
    },
    [mutate, trigger]
  );

  return [deleteKey, mutation] as const;
}

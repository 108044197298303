import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { UsageIcon } from "./PrimaryNav/UsageIcon";
import { ComingSoonBadge } from "./PrimaryNav/ComingSoonBadge";
import { DashboardIcon } from "./PrimaryNav/DashboardIcon";
import { APIKeysIcon } from "./PrimaryNav/APIKeysIcon";

const primaryLinks = [
  { to: "/dashboard", label: "Dashboard", icon: <DashboardIcon /> },
  {
    to: "/usage",
    label: "Usage",
    icon: <UsageIcon />,
    badge: <ComingSoonBadge />,
  },
  { to: "/api-keys", label: "API keys", icon: <APIKeysIcon /> },
];

export default function PrimaryNav() {
  return (
    <Nav>
      {primaryLinks.map((link, index) => (
        <StyledNavLink key={index} to={link.to}>
          {link.icon}
          {link.label}
          {link.badge}
        </StyledNavLink>
      ))}
    </Nav>
  );
}

const Nav = styled.nav.attrs(() => ({
  id: "sidebar-primary",
}))`
  flex: 1 1 100%;
  margin-bottom: 50px;
  z-index: 1;
`;

const StyledNavLink = styled(NavLink)`
  padding: 10px 0;
  text-decoration: none;

  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  font-size: 17px;
  gap: 12px;
  color: inherit;

  &.active {
    background: ${({ theme }) => theme.colors.yellow};
    color: #000;
    font-weight: 800;
  }
  @media (max-width: 1024px) {
    padding: 0 22px;
  }
`;
